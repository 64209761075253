.slide-in{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #e6e4e6;
    transform-origin: bottom;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slide-out{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #e6e4e6;
    transform-origin: top;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}