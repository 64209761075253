@import './Home/Home.scss';
@import './Empresa/Empresa.scss';
@import './Contato/Contato.scss';
@import './Produtos/Produtos.scss';
@import './Dicas/Dicas.scss';
@import './Fonts/Fonts.scss';
@import './Navbar/Navbar.scss';
@import './Footer/Footer.scss';
@import './Flotantes/Flotantes.scss';
@import './Transicion/Transicion.scss';


// General

:root{
    --amarillo: 255, 223, 14;
    --negro: 38, 35, 23;
}
.poppins{
    font-family: 'Poppins', sans-serif;
}
.titulo-grande{
    font-size: clamp(2.4rem, 0.4615rem + 2.6154vw, 3.6rem);
    color: #ffdf0e;
}
.titulo-mediano{
    font-size: clamp(1rem, 0.3077rem + 1.4103vw, 2rem);
    color: #ffdf0e;
}
.seccion-grande{
    min-height: 50vh;
}
.bg-amarillo-1{
    background: rgba(var(--amarillo),1);
}
.bg-amarillo-2{
    background: rgba(var(--amarillo),0.8);
}
.bg-amarillo-3{
    background: rgba(var(--amarillo),0.6);
}
.bg-amarillo-4{
    background: rgba(var(--amarillo),0.4);
}
.bg-amarillo-5{
    background: rgba(var(--amarillo),0.2);
}
.bg-negro{
    background: #343434;
}
.z-5{
    z-index: 5;
}
.z-10{
    z-index: 10;
}
.z-15{
    z-index: 15;
}
.z-20{
    z-index: 20;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

