@import './ProdutoDetail.scss';

.produtos-container{
    margin-top: 10vh;
}
.produtos-container .items-largos{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.produtos-container .item-largo{
    width: 40%;
    min-height: 100px;
    aspect-ratio: 3/1;
    overflow: hidden;
    position: relative;
    padding: 0 !important;
    margin: 0;
}
.produtos-container .item-largo .overlay{
    opacity: 1;
}
.produtos-container .item-largo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s;
}
.produtos-container .item-largo .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
}
.produtos-container .item-largo:hover img{
    scale: 1.2;
}
.produtos-container .item-largo .overlay p{
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
}
.produtos-container .items{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0;
}
.produtos-container .item{
    width: 30%;
    min-height: 200px;
    position: relative;
    padding: 0;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
.produtos-container .item img{
    width: 100%;
    aspect-ratio: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    object-fit: cover;
    transition: 0.5s;
}
.produtos-container .item:hover img{
    scale: 1.2;
}
.produtos-container .item:hover .overlay{
    opacity: 1;
    cursor: pointer;
}
.produtos-container .overlay{
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    opacity: 0;
}
.produtos-container .overlay p{
    text-transform: uppercase;
    color: white;
    text-align: center;
}
.produtos-container .overlay button{
    padding: 10px 25px;
    background: white;
    border: none;
    transition: 0.6s;
}
.produtos-container .overlay button a{
    text-decoration: none;
    color: black;
}
.produtos-container .overlay button:hover{
    background: rgb(var(--amarillo));
    scale: 1.1;
}
/* MEDIDE */
.medide-container{
    margin-top: 10vh;
}
.medide-container .contenido{
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.medide-container .contenido h1{
    border-bottom: 2px solid rgba(var(--amarillo), 1);
}
.medide-container .imagen img{
    width: 100%;
    box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.1);
}
/* UTILIDADE */
.utilidade-container{
    margin-top: 10vh;
}
.utilidade-container td a{
    display: flex;
    justify-content: center;
}

@media(max-width:1400px){
    .produtos-container .items{
        padding: 0 25px;
    }
}
@media(max-width:1000px){
    .produtos-container .item{
        min-width: 200px;
        width: 25%;
        aspect-ratio: 1;
    }
    .fs-2{
        font-size: 1.5rem !important;
    }
    .medide-container .row{
        flex-direction: column-reverse;
        align-items: center;
        gap: 3rem;
    }
}
@media(max-width:700px){
    .produtos-container .items{
        justify-content: space-around !important;
    }
    .fs-2{
        font-size: 1.1rem !important;
    }
    .produtos-container .overlay button{
        padding: 6px 15px;
    }
}
@media(max-width:500px){
    .fs-2{
        font-size: 1rem !important;
    }
    .produtos-container .overlay button{
        padding: 2px 15px;
    }
    .produtos-container .item{
        min-width: 130px;
        min-height: 130px;
        width: 40%;
    }
    .produtos-container .item-largo .overlay p{
        font-size: 16px;
    }
}