.empresa-container{
    margin-top: 12.5vh;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 36px;
}
.empresa-container .intro{
    background: #f1f1f1;
    min-height: 70vh;
}
.empresa-container .intro p{
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}