#hamburger_checkbox{
    display: none;
}
.hamburger_button{
    transition: 1s;
    position: fixed;
    top: 15px;
    right: 25px;
    width: 40px;
    height: 35px;
    display: none;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
}
.hamburger_button span{
    display: block;
    margin: 8px auto;
    width: 25px;
    height: 2px;
    background: black;
    border-radius: 100px;
    transition: 0.5s;
}
#hamburger_checkbox:checked ~ .hamburger_button span:nth-child(1){
    position: absolute;
    right: 30%;
    left: 30%;
    width: 30px;
    transform: rotate(45deg);
    transform-origin: top left;
}
#hamburger_checkbox:checked ~ .hamburger_button span:nth-child(2){
    position: absolute;
    opacity: 0;
    top: 40%;
    bottom: 40%;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    
}
#hamburger_checkbox:checked ~ .hamburger_button span:nth-child(3){
    position: absolute;
    width: 30px;
    top: 25px;
    right: 30%;
    left: 30%;
    transform: rotate(-45deg);
    transform-origin: bottom left;
}

