.contacto-container{
    margin-top: 12.5vh;
}
.contacto-container h3{
    color: rgba(0,0,0,0.6);
}
.contacto-container .item{
    height: 90px;
    width: auto;
    margin: 25px 0;
}
.contacto-container .icono{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--amarillo),0.6);
    height: 100%;
    aspect-ratio: 1;
    border-radius: 5px;
}
.contacto-container .contenido{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.contacto-container .contenido span{
    text-transform: uppercase;
    font-weight: 700;
}
.contacto-container .contenido, .contacto-container .contenido a{
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: black;
}
.contacto-container .contenido a:hover{
    color: rgb(var(--amarillo));
}
.contacto-form{
    background: rgba(var(--amarillo),0.2);
    border-radius: 5px;
    margin: 50px 0;
}
.contacto-form textarea{
    min-height: 200px;
}
.contacto-form button{
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    background: rgba(var(--amarillo),1);
    text-transform: uppercase;
    font-size: 12px;
}
.contacto-form button:hover{
    cursor: pointer;
    background: black;
    color: white;
}
@media(max-width:900px){
    
}
