@import './HamburgerButtons.scss';

.navbar{
    height: 11vh;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    z-index: 100;
}
.navbar div ul{
    height: auto;
    justify-content: flex-end;
    font-size: 20px;
}
.navbar ul li a{
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    max-height: 100%;
    position: relative;
    width: auto;
}
.navbar ul li a:hover, .navbar .fone:hover span, .navbar .fone:hover span svg{
    color: rgba(var(--amarillo, 1));
    stroke: rgba(var(--amarillo,1));
}
.navbar ul li a:focus::before{
    width: 45px;
    color: black;
    background: black;
}
.navbar ul li a::before{
    transition: 0.4s ease;
    position: absolute;
    content: '';
    bottom: -5px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: rgba(var(--amarillo, 1));
    width: 0;
}
.navbar ul li a:hover::before{
    width: 40px;
}
.navbar .items{
    gap: 3rem;
}
.navbar ul li .dropdown-wrapper {
    position: relative;
}
.navbar ul li .dropdown-content {
    display: none;
    transform: translateX(-40%);
    position: absolute;
    padding-top: 35px;
    top: 100%;
    left: 0;
    background-color: white;
    border-top: none;
    width: 330px;
}
.navbar ul li .dropdown-content li{
    width: 100%;
    padding: 15px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);

}
.navbar ul li:hover .dropdown-content {
    display: block;
}

/*------ LOGO ---------*/
.navbar .logo-container{
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.navbar .logo-container a{
    text-transform: uppercase;
    color: black;
    text-decoration: none;
    font-weight: 500;
}
.navbar .logo{
    height: 90px;
}
.navbar .logo img{
    height: 100%;
}

@media (max-width:1350px){
    .navbar ul .gap-5{
        gap: 1rem !important;
    }
    .navbar .items{
        gap: 2rem;
    }
}
@media (max-width:1200px) {
    .navbar{
        height: auto;
        position: fixed;
        width: 100%;
        top: 0;
        background: white;
        z-index: 10;
    }
    .navbar div ul{
        gap: 0 !important;
        height: auto !important;
        overflow: hidden;
        flex-direction: column;
        padding: 0 80px !important;
    }
    .navbar .items{
        height: 0;
        overflow: hidden;
        transition: 0.8s ease;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-top: 1px solid rgba(0, 0, 0, 0.075);
    }
    .navbar .items li{
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
        padding-bottom: 15px;
    }
    .navbar .logo-container{
        position: static !important;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .navbar .logo-container .fone{
        display: none;
    }
    .navbar ul li:hover .dropdown-content {
        display: none;
    }
    .hamburger_button{
        display: block;
        z-index: 99;
    }
    //CHECKED
    #hamburger_checkbox:checked ~ .items{
        // height: auto;
        padding: 160px 0 145px 0;
    }
}
@media (max-width:900px) {
    .logo-container img{
        transform: translateX(-30%);
    }
}
@media (max-width:500px) {
    .navbar div ul{
        padding: 0px !important;
    }
    .hamburger_button{
        right: 10px;
    }
    .logo-container img{
        transform: translateX(0%);
    }
}
