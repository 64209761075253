.flotantes{
    position: fixed;
    bottom: 5%;
    right: 2.5%;
    align-items: flex-end;
    z-index: 100;
    pointer-events: none;
}
.whatsappIcon{
    width: 70px;
    border-radius: 25px;
    aspect-ratio: 1;
    pointer-events: all;
}
.consultaIcon{
    width: auto;
    height: 50px;
    padding: 10px;
    background: #323232;
    border-radius: 50vw;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.493);
    pointer-events: all;

}
.whatsappIcon:hover, .consultaIcon:hover{
    cursor: pointer;
}
.consultaIcon:hover{
    background: rgba(var(--amarillo),1);
}
.consultaIcon:hover p{
    color: black;
}
.consultaIcon p{
    transform: translateY(30%);
    font-weight: 500;
    color: white;
}
.consultaIcon div{
    height: 50px;
    aspect-ratio: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.consultaIcon div img{
    border-radius: 25px;
    aspect-ratio: 1;
    width: 80%;
    object-fit: cover;
}