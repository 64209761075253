@import "~react-image-gallery/styles/scss/image-gallery.scss";
.imagen-intro{
    width: 100%;
    margin-top: 10vh;
}
.imagen-intro .image-gallery-slide-wrapper{
    margin-top: 100px;
    max-height: 85vh;
    overflow: hidden;
}
.imagen-intro .image-gallery-swipe{
    width: 100%;
}
.imagen-intro .image-gallery-swipe .image-gallery-slides img{
    width: 100%;
    object-fit: cover !important;
    transform: translateY(-100px);
}
.imagen-intro .image-gallery-swipe .image-gallery-slides .image-gallery-image{
    width: 100%;
    min-height: 200px;
    object-fit: cover;
}
.image-gallery-image{
    height: 50vh;
}
.imagen-intro .image-gallery-swipe .image-gallery-slides .image-gallery-image:hover{
    cursor: default;
}
.custom-controls-container .seccion {
    position: absolute; /* Use absolute positioning to place it correctly */
    max-width: 30%;
    height: 85vh;
    top: 0;
    background: rgba(2, 2, 2, 0.4); /* rgba with black color for transparency */
    z-index: 5; /* Ensure this is below the image's z-index if needed */
    pointer-events: none; /* Optional: prevents the div from interfering with clicks */
}
.custom-controls-container .seccion button{
    width: 100px;
    padding: 10px 15px;
    background: none;
    border: 1px solid black;
    transition: 0.3s;
    font-weight: 600;
}

.custom-controls-container .seccion button a{
    color: white;
    text-decoration: none;
}
.custom-controls-container .seccion button:hover{
    background: white;
}
.custom-controls-container .seccion button:hover a{
    color: black;
}
.custom-controls-container .derecha{
    right: 0;
}
.custom-controls-container .derecha div{
    align-items: flex-end;
    // padding-left: 100px;
}

.custom-controls-container .izquierda{
    left: 0;
}
.custom-controls-container .izquierda div{
    padding-right: 50px;
}
.custom-controls-container .izquierda div h2, .custom-controls-container .derecha div h2{
    margin: 0;
    transform: translateY(10%);
}
.izquierda, .derecha{
    text-justify: auto;
}
.izquierda *{
    text-align: left;
}
.derecha *{
    text-align: left;
}
.image-gallery-content{
    line-height: normal;
}
/* INTRO */

.galeria-intro {
    max-height: 85vh;
    margin-top: 105px;
    overflow: hidden;
    position: relative;
}

.galeria-intro img {
    height: 100%;
    width: 99vw;
    object-fit: cover;
}

.galeria-intro video {
    width: 99vw;
    height: 100%; /* Asegura que el video ocupe el alto completo */
}

.bullets {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 50px;
}

.bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid white;
    box-shadow: 0 3px 4px rgba(0,0,0,0.7);
    margin: 0 5px;
    cursor: pointer;
    transition: background 0.3s;
    user-select: none;
}

.bullet.active {
    background: #fff; /* Color para el bullet activo */
}

.img-overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 30%;
    background: rgba(0,0,0,0.3);
    height: 100%;
    max-height: 85vh;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}
.img-description-below{
    display: none;
    background: black;
}
.galeria-intro .img-overlay  a, .img-description-below a{
    background: transparent;
    border-radius: 10px;
    border: 1px solid white;
    padding: 10px 25px;
    height: 100%;
    color: white;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .3s;
    font-weight: 500;
    width: fit-content !important;
}
.galeria-intro .img-overlay a:hover, .img-description-below a:hover{
    color: black;
    background: #fffd0e;
    border: 1px solid #fffd0e;
    box-shadow: 0px 5px 5px rgba(255, 255, 255, 0.3);
}
.pronta-entrega{
    color: rgb(0, 173, 0);
    background: rgba(0,0,0,0.2);
    border: 1px solid black;
    padding: 10px 25px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 700;
    font-size: 42px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -300px;
    bottom: 60px;
}


.contenido-intro h1{
    text-transform: uppercase;
    position: relative;
}
.contenido-intro h1::before,.contenido-intro h1::after{
    position: absolute;
    content: '';
    top: 0;
    height: 50%;
    // transform: translateY(60%);
    width: 10px;
    background: rgba(var(--amarillo),0.6);
}
.contenido-intro h1::before{
    transform: translate(100%,60%);
}
.contenido-intro h1::after{
    transform: translate(-100%,60%);
}

.contenido-intro h1::before{
    left: -20px;
}
.contenido-intro h1::after{
    right: -20px;
}
.main-container .divider{
    background: rgba(var(--amarillo),0.7);
}

/* Galeria */

.image-gallery-swipe{
    box-shadow: 0px 10px 15px 5px rgba(0,0,0,0.1);
}
.image-gallery-thumbnail{
    width: 200px;
    margin: 10px 15px;
    box-shadow: 0px 10px 15px -8px rgba(0,0,0,0.1);
}
.image-gallery-slide-wrapper button, .image-gallery-bullets{
    z-index: 8;
}
.image-gallery-svg{
    z-index: 8;
}
.custom-controls-container div p {
    color: black;
    font-weight: 500;
    // text-align: center; /* Ensures the text is centered within the paragraph */
}


@media(max-width:1500px){
    .imagen-intro .image-gallery-swipe .image-gallery-slides img{
        transform: translateY(0px);
    }
}
@media(max-width:1450px){
    .pronta-entrega{
        right: -400px;
    }
}
@media(max-width:1300px){
    .custom-controls-container .seccion{
        height: 80vh;
        max-width: 40%;
    }
    .galeria-intro img, .galeria-intro video {
        transform:translateY(0px);
        min-height:300px;
    }
}
@media(max-width:1150px){
    .custom-controls-container .derecha div,.custom-controls-container .derecha div{
	z-index: 5;
	height: auto; /* Permite que la altura se ajuste proporcionalmente */
    object-fit: contain; /* Asegura que la imagen completa sea visible */
    min-height: auto; /* Elimina la altura mínima para permitir que la imagen se adapte */
    }
    .custom-controls-container .seccion{
        height: 70vh;
    }
    .galeria-intro .img-overlay{
        width: 40%;
    }
}
@media(max-width:1050px){
    .custom-controls-container .seccion{
        max-width: 100%;
        height: 30vh;
        top: 280px;
        position: absolute !important;
    }
}
@media(max-width:1000px){
    .galeria-intro .img-overlay{
        display: none;
    }
    .img-description-below{
        display: flex;
    }
    .pronta-entrega{
        position: static;
        background: rgba(255, 255, 255, 0.1);
        width: fit-content;
        margin: 35px 0;
        font-size: 32px;
    }
}
@media(max-width:900px){
    .imagen-intro .titulo{
        margin-bottom: 70px
    }
    .image-gallery-slide-wrapper{
        height: 450px !important;
        overflow: hidden;
    }
}
@media (max-width:500px){
    .image-gallery-thumbnail{
        // width: 100px;
        margin: 10px 5px;
    }
    .image-gallery-swipe, .image-gallery-slide{
        height: fit-content;
    }
    .imagen-intro .image-gallery-swipe .image-gallery-slides .image-gallery-image{
        width: 100%;
        // min-height: 33vh;
        object-fit: contain;
    }
    .image-gallery-image{
        height: auto !important;
    }

    .imagen-intro .image-gallery-slide-wrapper{
        height: unset;
    }
    .imagen-intro .image-gallery-swipe .image-gallery-slides{
        height: unset;
    }
    .imagen-intro .custom-controls-container{
        display: none !important;
    }
}