.produto-detail-container{
    margin-top: 10vh;
}
.produto-detail-container div{
    padding: 0;
}
.produto-detail-container .produto-imagen{
    min-height: 465px;
    display: flex;
    align-items: center;
}
.produto-detail-container .produto-imagen img{
    max-height: 465px;
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.produto-detail-container .produto-descripcion h2{
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.produto-detail-container .produto-descripcion .fs-5{
    font-size: 1.1rem !important;
}
.produto-detail-container .flechas{
    position: absolute;
    bottom: 0;
}
.produto-detail-container .flechas svg:hover{
    cursor: pointer;
    scale: 1.2;
}

@media(max-width:767px){
    .produto-detail-container .row{
        flex-direction: column;
    }
    .produto-detail-container .produto-imagen img{
        // aspect-ratio: 2/1;
        height: auto;
        max-height: fit-content;
    }
}