.dicas-container{
    margin-top: 12.5vh;
    height: auto;
}
.dicas-container .logo-v{
    transform: scale(200%) translate(35%, 24%);
    height: 280px;
}
.dicas-container .big-title span::before{
    position: absolute;
    height: 75%;
    width: 15px;
    transform: translateY(20%);
    background: rgb(var(--amarillo));
    left: -45px;
    top: 0;
    content: '';
}
.dicas-container .big-title span::after{
    position: absolute;
    height: 75%;
    width: 15px;
    transform: translateY(20%);
    background: rgb(var(--amarillo));
    right: -45px;
    top: 0;
    content: '';
}
.dicas-container .dica{
    margin: 15px 0;
    background: rgba(var(--amarillo),0.4);
    border-radius: 30vw;
    position: relative;
    z-index: 2;
    height: 160px;
}
.dicas-container .dica p{
    margin-left: 225px;
    margin-right: 25px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    transition: 0.3s;
}
.dicas-container .reverse{
    flex-direction: row-reverse;
}
.dicas-container .reverse p{
    margin-right: 225px;
    margin-left: 25px;
    font-size: 0.9rem;
}
.dicas-container .icon{
    z-index: 3;
    border: 25px solid white;
    margin: 15px 0;
    height: 180px;
    transform: translate(-2px, -11px);
    aspect-ratio: 1;
    border-radius: 30vw;
    background: #242424;
    transition: 0.3s;
}
.dicas-container .reverse .icon{
    transform: translate(2px, -11px);
}
.dicas-container .icon svg{
    stroke: rgb(var(--amarillo));
}
.dicas-container .icon:hover{
    background: rgba(var(--amarillo),1);
}
.dicas-container .icon:hover svg{
    stroke: black;
}
.dicas-container .icon:hover ~ .dica{
    background: rgb(54, 54, 54);
}
.dicas-container .icon:hover ~ .dica p{
    color: white;
}
.dicas-container .icon:nth-child(even){
    right: 0;
}
@media(max-width:960px){
    .dicas-container .col-3{
        height: 500px !important;
    }
    .logo-h{
        height: 200px !important;
        justify-self: center;
    }
}
@media(max-width:700px){
    .dicas-container .dica{
        height: fit-content;
        border-radius: 75px;
        padding: 25px !important;
    }
    .dicas-container .dica p{
        text-align: initial;
    }
}
@media(max-width:600px){
    .dicas-container .dica{
        border-radius: 25px;
    }
    .dicas-container .icon{
        left: 50%;
        right: 50%;
        transform: translateX(-50%) !important;
    }
    .dicas-container .reverse{
    }
    .dicas-container .dica p{
        margin-left: 0;
        margin-right: 0;
        margin-top: 200px;
    }
}