.footer{
    min-height: 15vh;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.footer .col:not(:nth-child(3)){
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.footer a{
    text-decoration: none;
    color: black;
}
.footer a:hover{
    color: rgba(var(--amarillo),1);
}
.footer ul li{
    position: relative;
}
.footer ul li::before{
    content: '';
    background: rgba(var(--amarillo),1);
    width: 7px;
    height: 50%;
    transform: translateY(50%);
    position: absolute;
    left: -15px;
}
.footer ul li a{
    text-decoration: none;
    color: black;
}
.footer ul li a:hover{
    border-bottom: 1px solid rgb(var(--amarillo));
    cursor: pointer;
}
.footer button{
    width: 150px;
    padding: 5px;
    border-radius: 15px;
    background: black;
    display: flex;
    justify-content: center;
}
.footer button a{
    color: white;
    font-weight: 600;
    font-size: 14px;
}
.footer button:hover{
    background: rgb(var(--amarillo));
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.footer button:hover a{
    color: black;
}

@media(max-width:900px){
    .footer .row{
        flex-direction: column;
    }
    .footer .col{
        padding: 0 25px !important;
        margin: 25px 0 10px 0!important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-right: none;
    }
    .footer .col:nth-child(3){
        padding-bottom: 90px!important;
    }
}